import React from 'react';
import { graphql } from 'gatsby';
import { roomsCategoryIds, setInnerHtml } from 'utils';
import { RoomsList } from 'components/roomsList';
import { Layout } from 'components/layout';
import { withIntlProvider } from 'components/withIntl';
import { HomePageProps } from './types';

const HomePage = (props: HomePageProps) => {
  const category = props.data.allWpCategory.edges.find(category => roomsCategoryIds.includes(category.node.id));
  const seoTitle = category?.node.seo?.title || '';
  const categoryName = category?.node.name || '';

  const renderCategoryDescription = () => (
    <div
      className="mb-30px"
      dangerouslySetInnerHTML={setInnerHtml(category?.node.description || '')}
    />
  );

  return (
    <Layout
      location={props.location}
      isHomePage
      title={categoryName}
      seoTitle={seoTitle}
      showSlider
    >
      {props => (
        <>
          {renderCategoryDescription()}
          <RoomsList rooms={props.rooms} />
        </>
      )}
    </Layout>
  );
};

export default withIntlProvider(HomePage);

export const pageQuery = graphql`
  query HomePageData($locale: String!) {
    allWpCategory(filter: {language: {language: {eq: $locale }}}) {
      edges {
        node {
          uri
          name
          id
          language {
            language
          }
          description
          seo {
            title
          }
        }
      }
    }
  }
`;
